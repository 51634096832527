<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">增加/修改 仓库</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>仓库名称</b></label>
            <div>
              <el-input type="text" v-model="model.STORAGE_NAME" style="width: 60%"/>
            </div>
          </div>
          <div class="form-group">
            <label><b>位置</b></label>
            <div>
              <el-input type="text" v-model="model.STORAGE_LOCATION"  style="width: 60%"/>
            </div>
          </div>
          <div class="form-group">
            <label><b>库管</b></label>
            <div>
              <el-select v-model="model.MAN_USERS_LIST" placeholder="请选择" multiple style="width: 80%">
                <el-option
                    v-for="item in tea.ls"
                    :key="item.ID"
                    :label="item.NAME"
                    :value="item.ID">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data(){
    return{
      isUpdate:false,
      sta:{
        show:false,
        loading:false
      },
      model: {},
      tea:{
        ls:[],
      },
    }
  },
  methods:{
    init(model){
      let self = this
      this.sta={show:true,loading:false}
      if (model==undefined || model==null) {
        this.model = {}
        this.isUpdate = false
      } else {
        model.MAN_USERS_LIST = model.MAN_USERS.split(',').map(n=>parseInt(n))
        this.model=JSON.parse((JSON.stringify(model)));
        this.isUpdate = true
      }
      if (this.tea.ls.length==0){
        this.whale.remote.getCollection({
          url:"/api/School/ORG/TeacherApi/GetList",
          completed(its){
            self.tea.ls=its;
          }
        })
      }
    },
    submit(){
      let self=this;
      self.sta.loading=true;
      if(this.isUpdate){
        this.whale.remote.getResult({
          url:"/api/School/MAT/MatStorage/Update",
          data:self.model,
          finally(){self.sta.loading=false;},
          completed:function (){
            self.sta.show=false;
            self.$emit("on-saved");
          }
        })
      }else{
        this.whale.remote.getResult({
          url:"/api/School/MAT/MatStorage/CreateNew",
          data:self.model,
          finally(){self.sta.loading=false;},
          completed:function (){
            self.sta.show=false;
            self.$emit("on-saved");
          }
        })
      }
    }
  }
}
</script>
