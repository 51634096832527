<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-shopping-cart-full"> </i> 物料管理 | 仓库列表</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="rowEdit()">新增仓库</el-button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-input v-model="grid.sea.STORAGE_NAME" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
                   @page-changed="loadPage">
              <el-table-column prop="STORAGE_NAME" label="仓库名称" width="200" align="center" />
              <el-table-column prop="STORAGE_LOCATION" label="位置" width="200" align="center" />
              <el-table-column prop="MAN_USERS_NAMES" label="库管" />
              <el-table-column label="操作" width="140">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-edit" @click="rowEdit(scope.row)">修改</el-button>
                  <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="stock_edit" @on-saved="getList" />
    </div>
  </div>
</template>
<style>
.el-radio {
  padding-top: 10px
}
</style>
<script>
import Edit from "./edit";
import Wgrid from "@/components/wgrid";
export default {
  name: "index",
  components: { Wgrid, Edit },
  data() {
    return {
      grid: {
        sea: {
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
          STORAGE_NAME:'',
          STORAGE_LOCATION:'',
        },
        ls: [],
        total: 0,
        loading: false
      },
    }
  },
  created() {
    this.getList();
  },
  methods: {
    filter() {
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi) {
      this.grid.sea.PAGE_INDEX = pi - 1;
      this.getList();
    },
    getList() {
      let self = this;
      this.grid.loading = true;
      this.whale.remote.getCollection({
        url: "/api/School/MAT/MatStorage/GetPageList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls = its;
          self.grid.total = n;
          self.grid.loading = false;
        }
      })
    },
    rowEdit(r) {
      this.$refs.stock_edit.init(r);
    },
    rowDel(r) {
      this.whale.delConfirm(() => {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/MAT/MatStorage/Delete?ID="+r.ID,
          completed: function () {
            //self.filter();
            self.grid.ls = self.grid.ls.filter(o => o.ID != r.ID)
          }
        })
      });
    },
  }
}
</script>

